.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: 80vh;
    font-family: "Merriweather", serif;
    align-items: center;
}
.h1 {
    font-size: 5vw;
    width: 80%;
    margin-bottom: 0px;
}
.p {
    font-size: 1.5vh;
    width: 80%;
}
.cardBox {
    display: grid;
    grid-column: span;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: auto;
    width: 65%;
    row-gap: 30px;
}

@media only screen and (max-width: 767px) {
    .cardBox {
        grid-template-columns: 1fr;
        row-gap: 30px;
        width: 80%;
        justify-content: center;
        margin-bottom: 30px;
    }
    .container {
        height: auto;
        gap: 30px;
    }

    .p {
        font-size: 3vw;
    }
}