.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 1vw;
    padding: 10px 20px;
    height: 150px;
    background-color: #FFFFFF;
}
.logoContainer {
    width: 250px; /* можешь настроить ширину логотипа по своему выбору */
    height: 100px;
}

.logo {
    width: 100%; /* можешь настроить ширину логотипа по своему выбору */
    height: 100%; /* можешь оставить такой высоты или изменить */
    object-fit: cover;
}

.ulUl {
    display: flex;
    list-style-type: none;
    margin-right: 20px;
    gap: 50px;
    text-decoration: none;
    color: black;
}

.link {
    text-decoration: none;
    color: black;
}
.link:hover{
    opacity: 0.6;
}

.contact {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact img {
    width: 40px; /* можешь изменить размер иконки телефона */
    height: 40px; /* можешь изменить размер иконки телефона */
    margin-right: 10px;
}
.contact img:hover {
    opacity: 0.6;
}
.contact button {
    background-color: #3B41DF; /* цвет фона кнопки */
    color: #FFFFFF; /* цвет текста кнопки */
    padding: 5px 10px;
    border: none;
    font-size: 16px;
    border-radius: 22.4px;
    cursor: pointer;
    width: 176px;
    height: 56px;
    font-family: 'Roboto Mono', monospace;
}
.contact button:hover{
    opacity: 0.7;
}
.menuIcon {
    display: none;
}
.menuBtn {
    display: none;
}
.dropdown {
    position: absolute;
    top: 100%;
    z-index: 10;
    left: 0;
    text-decoration: none;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display: flex;
    gap: 15px;
    width: 300px;
    padding: 15px;
    flex-direction: column;
}
.menuItem {
    position: relative;
    display: inline-block;;
}
@media only screen and (max-width: 767px) {
    .header {
        font-size: 16px;
        height: 80px;
    }
    .menu {
        display: none;
    }
    .menuIcon {
        display: block;
        width: 35px;
        height: 35px;
    }
    .menuBtn:hover{
        opacity: 0.6;
    }
    .menuBtn {
        display: block;
        border: none;
        background-color: #FFFFFF;
    }
    .contact img {
        width: 35px; /* можешь изменить размер иконки телефона */
        height: 35px; /* можешь изменить размер иконки телефона */
    }
    .contact button {
        display: none;
    }
    .menuSection {
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 60%;
        height: 100%;
        padding: 20px;
        font-family: "Merriweather", serif;

        /* Изначально меню вне экрана */
    }
    .menuOpen  {
    }
    .menuHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .closeBtn{
        border: none;
        width: 50px;
        height: 50px;
        background-color: white;
        margin-right: 5px;
    }
    .menuList{
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        text-decoration: none;
        list-style-type: none;
        gap: 12px;

    }
    .link {
        text-decoration: none;
        font-weight: bold;
        font-size: 4vw;
    }
    .button {
        background-color: #3B41DF; /* цвет фона кнопки */
        color: #FFFFFF; /* цвет текста кнопки */
        padding: 5px 10px;
        border: none;
        font-size: 16px;
        margin-top: 20px;
        border-radius: 22.4px;
        cursor: pointer;
        width: 80%;
        height: 56px;
        align-self: center;
        font-family: 'Roboto Mono', monospace;
    }
}