.designCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12vw;
    height: 15vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    text-decoration: none;
}
.designCard:hover {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.imageContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    font-size: 18px;
    font-weight: bold;
    color: black;
    transition: color 0.3s ease;
}

.arrow {
    width: 20px;
    height: 2px;
    background-color: #ccc;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.hovered {
    color: #3B41DF;
}

.hovered .arrow {
    background-color: #3B41DF;
}

@media only screen and (max-width: 767px) {
    .designCard {
        width: 70vw;
        height: auto;
    }
}