.footer {
    background-color: #f2f2f2;
    padding: 20px;
    font-family: 'Roboto Mono', monospace;
    font-size: 24px;
    display: grid;
    grid-template-columns: 2fr 1fr; /* Три равные колонки */
    grid-template-rows: 70px 300px;
}

.menu {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    gap: 20px;
    grid-row: 1 / span 1;
}

.menu a {
    margin: 0 10px;
    text-decoration: none;
    color: #333;
}
.menu a:hover {
    opacity: 0.6;
}


.socialIconsImg {
    width: 30px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    grid-row: 2 / span 2;
}

.contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    grid-row: 1 / span 2;
}

.contactIcon {
    margin-right: 10px;
}
.socIcon{
    width: 50px;
    height: 50px;
}
.socIcon:hover {
    opacity: 0.6;
}
.tegIcon {
    width: 32px;
    height: 32px;


}

.contactText {
    font-size: 14px;
}
.contactItem{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    text-align: start;

}

@media only screen and (max-width: 767px) {
    .footer {
        background-color: #f2f2f2;
        padding: 30px;
        font-family: 'Roboto Mono', monospace;
        font-size: 3.5vw;
        display: flex;
        flex-direction: column;
    }
    .menu {
        display: flex;
        justify-content: start;
        margin-bottom: 20px;
        gap: 30px;
        flex-direction: column;
    }
    .contactIcon {
        margin-left: 30px;
    }
    .tegIcon {
        width: 22px;
        height: 22px;
    }
    .contactText {
        font-size: 3.5vw;
    }
}

