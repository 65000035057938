.section {
    font-family: "Merriweather", serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
}
.imageContainer {
    width: 60%;
    height: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.h1 {
    font-size: 5vh;
}
.text {
    font-size: 1.75vh;
    width: 80%;
    list-style-type: none;
}
.title {
    width: 80%;
}
.main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    background-color: #f0f0f0;
    margin-bottom: 35px;
}


.h2{
    font-size: 2vw;
    text-transform: uppercase;
}
.container2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
}
.textBox2{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-items: center;
    align-items: center;
    margin-top: 40px;
}
.title2{
    width: 80%;
    border-top: 2px solid black;
}
.imageContainer2 {
    width: 30%;
    height: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}


.li {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.icon {
    width: 20px;
    height: 20px;
}
.p {
    width: 80%;
    font-size: 1.5vh;
}

.ul {
    font-size: 1.5vh;
    width: 80%;
    list-style-type: none;
    gap:20px;
    display: flex;
    flex-direction: column;
}
.liItem {

}

.imageContainer3 {
    width: 30%;
    height: 100%;
    margin-left: 5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.textBox3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    align-items: center;
    margin-top: 40px;
}

.container3 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45vh;
    align-items: center;
}

.textBox4 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}
.p2 {
    width: 50%;
}