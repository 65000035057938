.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    font-family: "Merriweather", serif;
    align-items: center;
    margin-bottom: 100px;
}
.h1 {
    font-size: 3vw;
    width: 80%;
    margin-bottom: 0px;
}
.p {
    font-size: 2vh;
    width: 80%;
}
.cardBox {
    display: grid;
    grid-column: span;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: auto;
    width: 80%;
    row-gap: 30px;
    column-gap: 30px;

}
.itemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    background-color: white;
    text-decoration: none;
    border-radius: 7.5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    width: 25vw;
    height: 50vh;
    text-align: center;

}
.imageContainer {
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
}


.img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

@media only screen and (max-width: 767px) {
    .h1 {
        font-size: 5vw;
    }
    .cardBox {
        grid-template-columns: 1fr;

    }
    .itemBox {
        width: 100%;
        height: 55vh;

    }
}