.formContainer {
    color: #fff;
    width: 100%;
    height: 60vh;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Merriweather", serif;
}
.title {
    font-size: 40px;
    color: black;
    margin-top: 70px;
    margin-left: 100px;
}

.text {
    font-size: 16px;
    color: black;
    text-align: start;
    width: 360px;
    margin-left: 100px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 160px;
    gap: 20px;
    margin-right: 50px;
}

.formRow {
    margin-bottom: 10px;
}

.button {
    background-color: #3B41DF; /* цвет фона кнопки */
    color: #FFFFFF; /* цвет текста кнопки */
    padding: 5px 10px;
    border: none;
    font-size: 16px;
    border-radius: 22.4px;
    cursor: pointer;
    width: 176px;
    height: 56px;
    font-family: 'Roboto Mono', monospace;
}

.button:hover {
    opacity: 0.7;
}
.input {
    width: 300px;
    height: 40px;
    border: none;
    border-radius: 3px;
}
@media only screen and (max-width: 767px) {
    .formContainer {
        height: auto;
        flex-direction: column;
    }
    .title {
        font-size: 5vw;
        margin-top: 30px;
        margin-left: 30px;
    }
    .text {
        font-size: 3vw;
        width: 80%;
        margin-left: 30px;
    }
    .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        gap: 20px;
        margin-left: 30px;
        margin-bottom: 30px;
    }
}