.factsContainer {
    text-align: start;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    font-family: "Merriweather", serif;
    padding-bottom: 40px;
}

.fact {
    margin-top: 10px;
    display: inline-block;
}

.number {
    color: #3B41DF;
    font-weight: bold;
    font-size: 64px;
}
.title {
    font-size: 40px;
}

.text {
    color: black;
    font-size: 24px;
    white-space: pre-line;
}
.factsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 80px;
}
.info {
    color: black;
    font-size: 20px;
    white-space: pre-line;
}
.fadeIn {
    transition: opacity 1s ease-in-out;
}
.endText {
    font-size: 30px;
}



show {
    opacity: 1;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1s ease-in-out;
}
.fadeIn2 {
    animation: fadeIn 1s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .title {
        font-size: 5vw;
    }
    .info {
        font-size: 3vw;
    }
    .endText {
        font-size: 3.5vw;
    }
    .factsContainer {
        padding-left: 30px;
        padding-right: 30px;
    }
}