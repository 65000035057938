.sliderContainer {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.sliderSlide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.5s ease;

}



.sliderSlide.active {
    opacity: 1;
}

.overlayText {
    position: absolute;

    color: white;
    font-size: 3vw;
    text-align: start;
    font-family: "Merriweather", serif;
    font-weight: bold;
    font-style: normal;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: start;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 400px;
    padding-left: 100px;
    color: white;
}


.button {
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    overflow: hidden;
    border: none;
    z-index: 10;
}
.button img {
    width: 100%;
    height: 100%;
    transform: scale(2.2);
}
.prev {
    right: 200px;
}
.next {
    right: 100px;
}

@media only screen and (max-width: 767px) {
    .sliderContainer {
        height: 30vh;
    }
    .overlayText {
        font-size: 5vw;
        padding-left: 20px;
        padding-top: 30%;
    }
    .button {
        display: none;
    }
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
