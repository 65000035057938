.contactInfoTable {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 85vh;
    background-color: #fbfbfb;
    justify-content: center;
    padding-left: 20px;
    margin-left: 100px;

}
.contactInfo {
    display: flex;
    flex-direction: column;
    padding: 100px;
    width: 40%;
    padding-right: 20px;

}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Merriweather", serif;
    background-color: #F5F5F5;
    column-gap: 100px;
}
.h2 {
    font-size: 2vw;
}
.h3 {
    font-size: 1.75vh;
}
.p{
    font-size: 1.5vh;

}
.imgBox {
    width: 40%;
    height: 80%;

}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}