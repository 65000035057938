.section {
    font-family: "Cormorant Garamond", serif;
    display: flex;
    flex-direction: column;
}
.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
}
.textBox{
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-items: center;
    align-items: center;
}
.imageContainer {
    width: 60%;
    height: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.h1 {
    font-size: 5vh;
    line-height: 6vh;
}
.text {
    font-size: 1.5vh;
    width: 80%;
    list-style-type: none;
    line-height: 2vh;
}
.title {
    width: 80%;
}
.imageContainer4 {
    width: 60%;
    height: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    margin-top: -150px;
}


.h2{
    font-size: 2vw;
    text-transform: uppercase;
}
.container2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60vh;
}
.textBox2{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-items: center;
    align-items: center;
    margin-top: 40px;
}
.title2{
    width: 80%;
    border-top: 2px solid black;
}
.imageContainer2 {
    width: 30%;
    height: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}


.li {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.icon {
    width: 20px;
    height: 20px;
}
.p {
    width: 80%;
    font-size: 1.5vh;
}

.ul {
    font-size: 1.5vh;
    width: 80%;
    list-style-type: none;
    gap:20px;
    display: flex;
    flex-direction: column;
}

.exampleBox {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 50vh;
}
.imageContainer5 {
    width: 50%;
    height: 100%;
}

.title3{
    width: 80%;
    text-align: center;
}

.imageContainer3 {
    width: 30%;
    height: 100%;
    margin-left: 5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.p2 {
    font-size: 2.3vh;
    width: 80%;
    text-align: center;
}
.textBox3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    align-items: center;
    margin-top: 40px;
}

@media only screen and (max-width: 767px) {
    .h1 {
        font-size: 5vw;
        line-height: 5.5vw;
    }
    .text {
        font-size: 3.5vw;
        width: 80%;
        line-height: 3.8vw;


    }
    .container {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
    }
    .imgMargin {
        z-index: -10;
        margin-top: -400px;
        opacity: 0.75;
    }
    .imageContainer {
        width: 100%;
        height: 60vh;
    }
    .p {
        width: 100%;
        font-size: 3vw;
    }
    .h2{
        font-size: 4vw;
        text-transform: uppercase;
    }
    .title2{
        width: 100%;
        border-top: 2px solid black;
    }
    .ul {
        font-size: 3vw;
        width: 100%;
    }

    .textBox2{
        width: 80%;
        align-self: center;
    }
    .container2 {
        flex-direction: column;
        height: auto;
        width: 80%;
        align-self: center;
        justify-content: center;
    }
    .imgMargin2 {
        display: none;
    }
    .section {
        margin-bottom: 0px;
    }
    .imageContainer5 {
        width: 100px;
        height: 130px;
    }
    .text4 {
        font-size: 3vw;
        width: 70%;
        line-height: 3.8vw;

    }
    .container4 {;
        height: 45vh;

    }

    .textBox{
        width: 100%;
    }
    .imageContainer3 {
        display: none;
    }

    .imageContainer4 {
        display: none;
    }

    .exampleBox {
        flex-direction: column;
        gap: 10px;
        height: auto;
    }

    .imageContainer5 {
        width: 90%;
        height: auto;
        align-self: center;
    }

}