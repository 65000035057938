.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: 85vh;
    font-family: "Merriweather", serif;
    align-items: center;
}
.h1 {
    font-size: 3vw;
    width: 80%;
    margin-bottom: 0;
}
.p {
    font-size: 1.5vh;
    width: 80%;
}
.imgContainer {
    width: 50%;
    height: 40%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
