.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    font-family: "Merriweather", serif;
    align-items: center;
    margin-bottom: 100px;
}
.h1 {
    font-size: 3vw;
    width: 80%;
    margin-bottom: 0px;
}
.p {
    font-size: 2vh;
    width: 80%;
}
.cardBox {
    display: grid;
    grid-column: span;
    grid-template-columns: 1fr 1fr ;
    grid-row: auto;
    width: 65%;
    row-gap: 30px;
    column-gap: 30px;

}
.itemBox {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    width: 30vw;
    height: 50vh;
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
@media only screen and (max-width: 767px) {
    .cardBox {
        grid-template-columns: 1fr ;
        width: 65%;

    }
    .h1 {
        font-size: 5vw;
    }
    .container {
        gap: 30px;
    }
    .itemBox {
        width: 100%;
        height: 50vh;
    }
}